.lds-dual-ring {
    display: flex;
    justify-content: center;
    align-items: center;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 6px solid #0060AE;
    border-left-color: transparent;
    animation: lds-dual-ring 1.3s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
