
@import './forCommonComponents.scss';
@import './_variables.scss';

.mw-500 {
    max-width: 500px;
}
.wfc {
    width: fit-content;
	max-width: 100%;
}

.wmc {
    width: min-content;
}

.w-max-content {
    width: max-content;
}

.wunset {
    width: unset;
}

.hmc {
    height: min-content;
}

.h28 {
    height: 28px;
}

.h42 {
    height: 42px;
}

.my-p-0 {
    padding: 0px !important;
}

.right-icon {
	right: 3px;
    line-height: 24px !important;
    position: absolute;
}

.m-p-0 {
    margin: 0px;
    padding: 0px;
}

.mtb-0 {
    margin-bottom: 0px;
}

.minh-100 {
	min-height: 100px;
}
.maxh-500 {
	max-height: 500px;
}
.maxh-170 {
    max-height: 170px;
  }

.pt-10 {
	padding-top: 10px;
}

.pl-0 {
	padding-left: 0;
}

.pr-0 {
	padding-right: 0;
}

.r-23 {
	right: 23px;
}

.btn-simple {
    height: 38px;
    text-align: center;
    padding: 0px 18px !important;
    border-radius: 20px;

    font-size: 14px;
    line-height: 36px;
    font-weight: bold;
    text-transform: uppercase;
}

.btn-simple-circle {
    padding: 0px 12px !important;
}

.col-bg-primary {
	color: #fff !important;
	background-color: $col-my-text-primary !important;
	border-color: $col-my-text-primary;
	:hover {
		color: #fff;
		background-color: $col-my-text-primary !important;
		border-color: $col-my-text-primary;
    }
}
.col-bg-add {
	color: #fff !important;
	background-color: $col-add !important;
	border-color: $col-add;
	:hover {
		color: #fff;
		background-color: $col-add !important;
		border-color: $col-add;
    }
}

.col-bg-edit {
	color: #fff !important;
	background-color: $col-edit !important;
	border-color: $col-edit;
	:hover {
		color: #fff;
		background-color: $col-edit !important;
		border-color: $col-edit;
    }
}

.col-bg-danger {
	color: #fff !important;
	background-color: $col-danger !important;
	border-color: $col-danger;
	:hover {
		color: #fff;
		background-color: $col-danger !important;
		border-color: $col-danger;
    }
}

.col-figma-text-primary {
	color: $col-figma-primary;
}

.col-warning {
	color: $col-warning;
}

.bgcol-figma-text-primary {
	background-color: $col-figma-primary;
}

.borcol-figma-text-primary {
	border-color: $col-figma-primary;
}

.col-figma-accent {
	color: $col-figma-accent !important;
}

.bgcol-figma-accent {
	background-color: $col-figma-accent !important;
}

.bordercol-figma-accent {
	border-color: $col-figma-accent;
	&:hover, &:active, &:focus {
		border-color: $col-figma-accent;
	}
}

.borcol-figma-accent {
	color: $col-figma-accent;
	background-color: #fff !important;
	border-color: $col-figma-accent;
}

.borcol-figma-accent-hover:hover {
	color: $col-figma-accent !important;
	background-color: #fff !important;
	border-color: $col-figma-accent !important;
}

.bgcol-figma-highlight {
	background-color: $col-figma-highlight;
}

.col-figma-not-active-middle {
	color: $col-figma-not-active-middle;
}

.col-not-active-item {
	color: $col-not-active-item;
}

.col-not-active-item-bg {
	background-color: $col-not-active-item;
}

.text-decoration-underline-hover:hover {
	text-decoration: underline;
}

.font-weight-bold {
	font-weight: bold;
}

.notifsBell {
	color: $col-my-text-primary;
	.notifsBellCount {
	    position: absolute;
	    top: -5px;
	    right: -10px;
	    width: 20px;
	    height: 20px;
	    border-radius: 10px;
	    border: 1px solid #fff;

	    background-color: #b3272f;
	    color: #fff;
	    text-align: center;
	    line-height: 18px;
		font-size: 12px;
	}
}

.hover:hover {
	cursor: pointer;
}

.active:active {
	transform: translate(0, 2px);
}

.col-add {
    color: $col-add !important;
}
.col-highlight {
    color: $col-highlight !important;
}

footer {
	position: relative;
	padding-right: 1.5rem;
    padding-left: 1.5rem;
	.carousel-container {
		width: calc(100% - 40px);
		display: flex;
    	justify-content: center;
		.desk-carousel {
			width: calc(100% - 40px);
			.react-multi-carousel-track {
				z-index: 1;
				.react-multi-carousel-item {
					height: 80px;
				}
			}
			.carousel-button-group {
				position: fixed;
				width: calc(100% - 290px);
    			transform: translateX(-25px);
				height: 80px;
    			display: flex;
    			align-items: center;
				
				.carousel-button {
					position: absolute;
					z-index: 2;
				}
			}
			.react-multi-carousel-dot-list {
				position: fixed;
				bottom: 5px;
				z-index: 1;
				button {
					width: 30px;
					height: 4px;
					-webkit-clip-path: polygon(4px 0, 100% 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%, 0 4px);
    				clip-path: polygon(4px 0, 100% 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%, 0 4px);
				}
			}
		}
		.carousel {
			max-width: 700px;
			margin: 0 auto;
			.carousel-indicators {
				margin-bottom: 0px;
				button {
					border-bottom-width: 5px;
					background-color: red;
				}
			}
			.carousel-inner {
				height: 80px;
				padding-bottom: 10px;
				.carousel-item {
					height: 100%;
				}
			}
		}
		
		.container-new {
			height: 70px;
			div {
				height: 100%;
				a {
					display: inline-block;
					height: 100%;
					max-width: 100%;
				}
			}
			img {
				object-fit: cover;
				width: 100%;
			}
		}
	}
	.btn-hide-news {
		position: absolute;
		top: 0px;
    	right: 0px;
	}
}
footer[data-footerisopen=true] {
	height: 80px;
}

.modal-filter-options {
	max-height: 560px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;
}

@media screen and (max-width: 1200px) {
	footer {
		padding-left: 4px;

		.footer-mobile {
			padding-right: 90px !important;
			
			.carousel-container {
				width: calc(100% - 0px);
	
				.desk-carousel {
					width: 100%;
				}
				.react-multi-carousel-track {
					.react-multi-carousel-item {
						height: 50px;
					}
				}
				.container-new {
					div {
						padding-right: 0;
					}
				}
			}
			.btn-hide-news {
				right: 70px;
			}
		}
	}
	footer[data-footerisopen=true] {
		height: 50px;
	}
}

.figma-dots {
	border: 0;
}

.opacity-04.inactive {
	opacity: 0.4;
}

.poll-start-end {
	font-size: 28px;
	line-height: 40px;
}

.star {
	.checked {
		color: #F9B522;
	}
}

.linear-gradient {
	background-image: repeating-linear-gradient(-45deg, #0000 0, #0000 10px, #ffffff26 10px, #ffffff26 20px), linear-gradient(90deg, rgba(0,97,175,1) 0%, rgba(22,156,225,1) 100%);
}

.poll-range {
  	background: #ECEFF4;
	.range-slider__thumb[data-lower] {
		width: 0;
	}
	.range-slider__range {
		border-radius: 4px;
		background: #CAD3DF;
	}
	.range-slider__thumb {
		width: 40px;
		height: 40px;
		background: transparent;
		border-radius: 0;
	}
	.range-slider__thumb[data-upper] {
		background: url("../../../public/assets/img/star.svg");
	}
}

.root-auth-qr {
	position: absolute !important;
    right: 0;
    bottom: 0;
	min-width: 360px;
	font-size: 12px;
}

.excel-template-import {
	margin-top: -15px;
	margin-bottom: -15px;
}

.mt--10px {
	margin-top: -10px;
}

@media screen and (max-width: 1200px){
    .root-auth-qr {
        position: relative !important;
    }
}
@media screen and (max-width: 800px){
    .poll-start-end {
        font-size: 4vw;
    	line-height: 7.5vw;
    }
}

.fake-doors-text {
	text-wrap: nowrap;
}
@media screen and (max-width: 350px){
    .fake-doors-text {
        font-size: 4vw;
    }
}

.userQRCodeSvgMobileModal {
	display: flex;
  	justify-content: center;
  	max-width: 100vw;
	svg {
		width: 100% !important;
		max-width: 90vw;
	}
}

.react-datepicker__year-dropdown {
	max-height: 250px;
	overflow-y: scroll;
}

.bor-rad-20px {
	border-radius: 21px !important;
}